<template>
  <div class="container pb-5 py-5 py-lg-0">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
        <div class="card bg-secondary border-0 mb-0">
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-center text-muted mb-4">
              <small>Sign in with Email</small>
            </div>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
              <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                <base-input
                  alternative
                  class="mb-3"
                  name="Email"
                  :rules="{ required: true, email: true }"
                  prepend-icon="ni ni-email-83"
                  placeholder="Email"
                  v-model="account.email"
                ></base-input>

                <base-input
                  alternative
                  class="mb-3"
                  name="Password"
                  :rules="{ required: true, }"
                  prepend-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Password"
                  v-model="account.password"
                ></base-input>
                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { login } from '@/services/auth'


export default {
  name: 'Login',
  data() {
    return {
      account: {
        email: '',
        password: '',
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["getInfoAuth"])
  },
  methods: {
    ...mapActions('auth', ['setAuth']),
    async onSubmit() {
      const payload = {
        email: this.account.email,
        password: this.account.password
      }
      try {
        const { data } = await login(payload)
        this.$cookies.set('CERT', data.token, '8h');
        this.setAuth(
          data.token
        )
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Login Success"
        });
        this.$router.push({ name: 'Dashboard-Admin' })
      } catch (error) {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: error
        });
      }
    }
  }
};
</script>
